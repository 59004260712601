export const checkFootnotes = () => {

  [...document.querySelectorAll('.references li')].forEach( (reference, index) => {
    reference.setAttribute('id', `reference__${index}`);
  });

  [...document.querySelectorAll('.article__content')].forEach( article => {
    var regex = /\{(.*?)\}/g;
    article.innerHTML = article.innerHTML.replace(regex, `<sup><a data-barba-prevent class="anchor-link" href='#reference__$1'>$1</a></sup>`);
  });
}
export const generateCTA = (payload) => {
  if (payload.namespace == 'home'){
    return generateCTAHome();
  }
  if (payload.namespace == 'overview') {
    return generateCTAOverview();
  } else {
    return generateCTAChild();
  }
}


const searchbar = document.querySelector('.searchbar');
const searchbarMenu = document.querySelector('.searchbar__menu');
const searchbarMenuWrapper = document.querySelector('.searchbar__menu--wrapper');

const generateCTAOverview = () => {
  const resultCTA = document.createElement("span");
  const resultList = document.createElement("li");
  resultList.dataset.fulltitle = '';
  const resultIcon = document.createElement("i");
  resultIcon.classList.add(...['fas', 'fa-sort-amount-up']);
  resultList.innerText = "Filter projects";
  resultList.appendChild(resultIcon);
  resultCTA.appendChild(resultList);
  resultCTA.classList.add('searchbar__results__cta');
  resultCTA.addEventListener('click', e => {
    e.preventDefault();
    searchbar.classList.add('searchbar--filter');
    document.body.classList.add('searchbar--filter');
    searchbarMenu.classList.add('searchbar__menu--filter')
    searchbarMenuWrapper.classList.add('searchbar__menu--filter')
  })
  return resultCTA;
}
const generateCTAHome = () => {
  const resultCTA = document.createElement("a");
  resultCTA.href = document.querySelector('.searchbar__results').dataset.overview;
  const resultList = document.createElement("li");
  resultList.dataset.fulltitle = '';
  const resultIcon = document.createElement("i");
  resultIcon.classList.add(...['fas', 'fa-home']);
  resultList.innerText = "Enter Aesthetics of Exclusion";
  resultList.appendChild(resultIcon);
  resultCTA.appendChild(resultList);
  resultCTA.classList.add('searchbar__results__cta');
  return resultCTA;
}

const generateCTAChild = () => {
  const resultCTA = document.createElement("a");
  resultCTA.href = document.querySelector('.searchbar__results').dataset.overview;
  const resultList = document.createElement("li");
  resultList.dataset.fulltitle = '';
  const resultIcon = document.createElement("i");
  resultIcon.classList.add(...['fas', 'fa-home']);
  resultList.innerText = "Back to overiew";
  resultList.appendChild(resultIcon);
  resultCTA.appendChild(resultList);
  resultCTA.classList.add('searchbar__results__cta');
  return resultCTA;
}
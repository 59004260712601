import { LuminousGallery } from 'luminous-lightbox';
import disableScroll from 'disable-scroll';


export const generateGallery = () => {
  const galleryImages = [...document.querySelectorAll(".gallery__image__link")];
  
  if (galleryImages.length > 0) {
    var options = {
      namespace: null,
      sourceAttribute: "data-href",
      openTrigger: "click",
      closeTrigger: "click",
      closeWithEscape: true,
      closeOnScroll: false,
      showCloseButton: true,
      appendToNode: document.getElementById('Content'),
      onOpen: () => {
        disableScroll.on();
      },
      onClose: () => {
        disableScroll.off();
      },
      includeImgixJSClass: false,
      injectBaseStyles: true
    };
    var galleryOpts = {
      arrowNavigation: true
    };
    new LuminousGallery(document.querySelectorAll(".gallery__image__link"), galleryOpts, options);
  }
}



import 'waypoints/lib/noframework.waypoints.min.js';

export const visionTags = (namespace) => {
  if (namespace == 'overview') {
    [...document.querySelectorAll('.item')].forEach((item, index) => {
      new Waypoint({
        element: item,
        handler: function (direction) {
          if (index % 2) {
            document.querySelector('.vision-tags__right span').innerText = item.dataset.type.trim();
          } else {
            document.querySelector('.vision-tags__left span').innerText = item.dataset.type.trim();
          }
        },
        offset: '50%'
      });
    })
  }
}
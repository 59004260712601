import barba from '@barba/core';
// import './_searchbar.js';
import { panoramaInit, panoramaDestroy } from './_home.js';
import { generateGallery } from './_gallery.js';
import { storeInstance } from './_searchbar.js';

import { checkImages } from './_image.js';
import { checkItemTextLength } from './_items.js';
import { checkLinks } from './_links.js';
import { headerScroll, headerTitle } from './_header.js';
import { checkFootnotes } from './_article.js';

import { searchOpactiyDrop, swiper } from './project-page.js';
import { visionTags } from './_vision-tags.js';
import { overviewSwiper, videoPlay } from './overview.js';

const namespace = document.querySelector('.page');
const searchbar = document.querySelector('.searchbar');

const init = (data) => {
  let currentNameSpace = namespace.dataset.barbaNamespace;
  if (data != null) {
    currentNameSpace = data.next.namespace;
  }

  checkImages();
  checkItemTextLength();
  checkLinks();
  generateGallery();
  window.scrollTo(0, 0);
  

  document.querySelector('.header').classList.remove('header--hidden');
  document.querySelector('.blocker').click();
 
  // visionTags(currentNameSpace);
  searchOpactiyDrop(currentNameSpace);
  swiper(currentNameSpace);
  overviewSwiper(currentNameSpace);
  videoPlay(currentNameSpace);
  checkFootnotes();
  
  if (currentNameSpace != 'home') {
    headerTitle(window.location.href, currentNameSpace);
    panoramaDestroy();
  }
  
  if (currentNameSpace == "home") {
    panoramaInit();
  }
  
}


storeInstance.dispatch('checkCTA', { 
  'namespace': document.querySelector('.page').dataset.barbaNamespace
});
headerScroll();

barba.init({
  // debug: true,
  transitions: [{
    leave: () => {
      document.body.classList.add('page--transitioning');
    },
    after: (data) => {
      storeInstance.dispatch('checkCTA', data.next);
      setTimeout(() => {
        init(data);
        document.body.classList.remove('page--transitioning')
      }, 200 );
    }
  }]
});

init(null);

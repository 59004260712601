import Store from 'beedle';
import disableScroll from 'disable-scroll';
import { generateCTA, generateCTAOverview} from './__searchbar_cta';

const searchbar = document.querySelector('.searchbar');
const searchbarMenuButton = document.querySelector('.searchbar__hamburger-container');
const searchbarMenu = document.querySelector('.searchbar__menu');
const searchbarMenuWrapper = document.querySelector('.searchbar__menu--wrapper');
const Content = document.getElementById('Content');

//Retrieve JSON of all of site index.
let globalSearch;
fetch(searchbar.dataset.search+"?"+Date.now)
  .then(res => res.json())
  .then(data => globalSearch = data)

const actions = {
  searchWebsite(context, payload) {
    let newList = [];
    if (payload != "") {
      newList = globalSearch.filter( item => {
        const lc = item.title.toLowerCase();
        const filter = payload.toLowerCase();
        return lc.includes(filter);
      });
      context.commit('setSearchResults', newList.slice(0,4));
      context.commit('setMessage', payload);
    }
  },
  emptyResults(context, payload) {
    context.commit('setSearchResults', []);
  },
  checkCTA(context, payload) {
    context.commit('setCTA', payload);
  }
};

const mutations = {
  setMessage(state, payload) {
    state.message = payload;
    return state;
  },
  setSearchResults(state, payload){
    state.searchResults = payload;
    return state;
  },
  setCTA(state, payload){
    if (window.innerWidth < 768) {
      // searchbar.classList.add('searchbar--static');
    }
    state.cta = generateCTA(payload);
    return state;
  }
};

const initialState = {
  message: '',
  classes: [''],
  searchResults: [],
  overview: ''
};

export const storeInstance = new Store({
  actions,
  mutations,
  initialState
});


const getSearch = (text) => {
  const searchUrl = document.querySelector('.searchbar__searchfield form').dataset.search;
  console.log(searchUrl);
  window.location.href = `${searchUrl}?q=${text}`; 
  
}

// Listen for searchbar input.
const textElement = document.querySelector('.searchbar__searchfield input');
textElement.addEventListener('input', (e) => {
  searchbar.classList.remove('searchbar--static');
  storeInstance.dispatch('searchWebsite', textElement.value.trim());
});
textElement.addEventListener('keypress', (e) => {
  const code = (e.keyCode ? e.keyCode : e.which);
  if (code == 13) {
    getSearch(textElement.value);
    e.preventDefault();
  }
});


textElement.addEventListener('click', e => {
  searchbar.classList.remove('searchbar--static');
})

// Listen for escape key.
document.onkeydown = function (evt) {
  evt = evt || window.event;
  var isEscape = false;
  if ("key" in evt) {
    isEscape = (evt.key === "Escape" || evt.key === "Esc");
  } else {
    isEscape = (evt.keyCode === 27);
  }
  if (isEscape) {
    searchbar.classList.add('searchbar--static');
  }
};

// document.querySelector('.page').addEventListener('click', e => {
//   searchbar.classList.add('searchbar--static');
// })

// Listen for state change.
const messageElement = document.querySelector('.searchbar__results');
storeInstance.subscribe(state => {
  // Map all search results.
  messageElement.innerHTML = state.searchResults.map(function (result) {
    let resultType = result.template;
    let resultTitle = result.title;
    let resultIcon = "fa-map-marker-alt";
    if (resultType == 'news-item') {
      resultType = 'news';
    }
    if (resultType == "") {
      resultIcon = "fa-flag"
    }
    return `<a href="${result.url}"><li data-fulltitle="${result.fulltitle}"><i class="fas ${resultIcon}"></i><span>${result.title}</span> ${resultType}</li></a>`;
  }).join('');

  // Add CTA.
  messageElement.appendChild(state.cta);

  // Add event listeners to newly rendered elements.
  [...messageElement.querySelectorAll('li')].forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault();
      textElement.value = item.dataset.fulltitle;
      storeInstance.dispatch('emptyResults');
    })
  })

});


searchbarMenuButton.addEventListener('click', e => {
  searchbar.classList.toggle('searchbar--filter');
  document.body.classList.toggle('searchbar--filter');
  searchbarMenu.classList.toggle('searchbar__menu--filter');
  searchbarMenuWrapper.classList.toggle('searchbar__menu--filter');
})

const blocker = document.querySelector('.blocker');
blocker.addEventListener('click', e => {
  searchbar.classList.remove('searchbar--filter');
  document.body.classList.remove('searchbar--filter');
  searchbarMenu.classList.remove('searchbar__menu--filter');
  searchbarMenuWrapper.classList.remove('searchbar__menu--filter');
})
const navbar = document.querySelector('.header');
const iconArrows = document.querySelector('.icon-arrows');
export const headerScroll = () => {
  let c = 0;
  let currentScrollTop = 0;
  
  window.addEventListener('scroll', () => {
    const a = window.scrollY;
    const b = navbar.offsetHeight;
    currentScrollTop = a;
    if (c < currentScrollTop && a > b + b) {
      navbar.classList.add("header--hidden");
      iconArrows.classList.add('icon-arrows--down');
      iconArrows.classList.remove('icon-arrows--up');
    } else if (c > currentScrollTop && !(a <= b)) {
      navbar.classList.remove("header--hidden");
      iconArrows.classList.remove('icon-arrows--down');
      iconArrows.classList.add('icon-arrows--up');
    }
    c = currentScrollTop;
  })
}

export const headerTitle = (url, namnespace) => {
  const headerTitle = document.querySelector('.header__title');
  fetch(url+'/json')
    .then(response => {
        if (response.status !== 200) return;
        response.json().then( data => {
          let headerText = data.header.title.value;
          if (data.header.parent != null) {
            headerText = `${data.header.parent.value} > ${data.header.title.value}` 
          }
          headerTitle.innerHTML = headerText;
        });
      }
    ).catch( err => {
      console.log('Fetch Error :-S', err);
    });
}
import imagesLoaded from 'imagesloaded';

export const checkItemTextLength = () => {
  const itemTextArr = [...document.querySelectorAll('.item')];
  itemTextArr.forEach((item) => {
    imagesLoaded(item, function (instance) {
      const image = item.querySelector('img');
      const text = item.querySelector('.item__text');
      // text.style.width = `${image.width}px`;
      item.classList.add('item--loaded');
    });
  })
}


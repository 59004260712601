export const checkLinks = () => {
  const linksArr = [...document.querySelectorAll("a")];
  linksArr.forEach(link => {
    const linkTest = new RegExp("/" + window.location.host + "/");
    if (!linkTest.test(link.href)) {
      link.addEventListener("click", event => {
        event.preventDefault();
        event.stopPropagation();
        window.open(link.href, "_blank");
      });
    }
  });
};

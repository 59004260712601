import 'waypoints/lib/noframework.waypoints.min.js';
import Swiper from 'swiper';
import Player from "@vimeo/player";

const searchbar = document.querySelector('.searchbar');
let waypoint = null;

export const searchOpactiyDrop = namespace => {
  if (namespace == 'project') {
    searchbar.classList.add('searchbar--project-hidden');
    waypoint = new Waypoint({
      element: document.querySelector('.article'),
      handler: function (direction) {
        searchbar.classList.toggle('searchbar--project-hidden');
      }
    })
  } else {
    if (waypoint !== null) {
      waypoint.destroy();
    }
    searchbar.classList.remove('searchbar--project-hidden');
  }
}

const captionChange = (slide) => {
  const captionDOM = document.querySelector('.project-page__featured-image__caption');
  captionDOM.innerHTML = slide.dataset.caption;
}

const imageSlide = (slide) => {
  const image = slide.querySelector('img');
  const hires = image.dataset.src;
  const newImage = new Image();
  newImage.onload = (e) => {
    image.src = newImage.src;
    image.classList.add('loaded');
  }
  newImage.src = hires;
}

const fullbleedSlide = (slide) => {
  const hires = slide.dataset.background;
  const newImage = new Image();
  newImage.onload = (e) => {
    slide.style.background = `url("${newImage.src}") center center / cover no-repeat`;
  }
  newImage.src = hires;
}

const vimeoVideos = {}


const initVimeoSlides = () => {
  [...document.querySelectorAll('.slideshow__slide--video')].forEach( (videoSlide, index) => {
    let isFullbleed = false;
    if (videoSlide.classList.contains('slideshow__slide--video--is-fullbleed')) {
      isFullbleed = true;
    }
    vimeoVideos[index] = new Player(videoSlide, {
      url: videoSlide.dataset.id,
      height: window.innerHeight,
      background: isFullbleed,
      responsive: true
    });
    videoSlide.dataset.index = index;
    // vimeoVideos[index].play();
    vimeoVideos[index].setVolume(0);

  })
}


const replaceVimeoMobile = swiper => {
  isMobile = true;
}



const slideBehaviour = (swiper) => {
  const slide = swiper.slides[swiper.activeIndex];
  if (!isMobile) {
    for (var key in vimeoVideos) {
      if (!vimeoVideos.hasOwnProperty(key)) continue;
      vimeoVideos[key].pause();
    }
    if (slide.classList.contains('slideshow__slide--fullbleed-video')) {
      const videoIndex = slide.querySelector('.slideshow__slide--video').dataset.index;
      vimeoVideos[videoIndex].play();
    }
  } else {
    fullbleedSlide(slide);
  }
  if (slide.classList.contains('slideshow__slide--image')) {
    imageSlide(slide);
  }
  if (slide.classList.contains('slideshow__slide--fullbleed')) {
    fullbleedSlide(slide);
  }
  captionChange(slide);
}

let isMobile = false;

export const swiper = namespace => {
  if (namespace == 'project') {
   
    const slideshowSwiper = new Swiper('.slideshow__swiper', {
      simulateTouch: false,
      keyboard: {
        enable: true 
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      on: {
        init: function(){
          window.innerWidth > 768 ? initVimeoSlides() : replaceVimeoMobile();
          slideBehaviour(this);
          if(this.slides.length == 1) {
            document.querySelector('.swiper-pagination').style.display = 'none';
          }
        },
        slideChange: function(){
          slideBehaviour(this);
          
        }
      }
    });
    

  }
}
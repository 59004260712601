export const checkImages = () => {
  const featuredImageArr = [...document.querySelectorAll('.project-page__featured-image')];
  featuredImageArr.forEach((featuredImage) => {
    const backgroundURL = featuredImage.dataset.background;
    const backgroundImage = new Image();
    backgroundImage.onload = function () {
      featuredImage.setAttribute('style', `background: url(${backgroundURL}) no-repeat center center; background-size:cover;`);
    }
    backgroundImage.src = backgroundURL;
  })
}



import Swiper from 'swiper';

export const videoPlay = namespace => {
  let timer;

  const randRange = data => {
    var newTime = data[Math.floor(data.length * Math.random())];
    return newTime;
  }

  const playVideo = video => {
    video.play();
  }


  if(namespace == 'overview') {
    const video = document.getElementById('logoVideo');
    video.addEventListener('ended', e => {
      var timeArray = new Array(3000, 8000, 5000, 10000);
      timer = setTimeout(() => {
        playVideo(video)
      }, randRange(timeArray));
    })
  } else {
    if(timer != null) {
      clearTimeout(timer);
    }
  }
}

  export const overviewSwiper = namespace => {
    if (namespace == 'overview') {

      const slideshowSwiper = new Swiper('.swiper-container--overview', {
        simulateTouch: false,
        slidesPerView: 'auto',
        centeredSlides: false,
        keyboard: {
          enable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          init: function () {

          },
          slideChange: function(){
            if(this.activeIndex == this.slides.length -2 ){
              this.allowSlideNext = false;
              document.querySelector('.swiper-button-next').style.display = 'none';
            } else {
              this.allowSlideNext = true;
              document.querySelector('.swiper-button-next').style.display = 'block';
            }
          }
        }
      });


    }
  }


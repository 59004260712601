import 'pannellum';
import disableScroll from 'disable-scroll';

var body = document.body;
let pViewer;
var initial;


var MouseWheelHandler = function (e) {
  var e = window.event || e;
  let yawChange = pViewer.getYaw() + (e.deltaY*0.05);
  pViewer.stopAutoRotate();
  clearTimeout(initial);
  initial = window.setTimeout(() => { pViewer.startAutoRotate(baseSpeed / teleSizeRatio) }, 2000);
  pViewer.setYaw(yawChange, false);
}



export const panoramaInit = () => {
  disableScroll.on();
  const panoramaImage = document.querySelector('.streetview');
  const teleprompter = panoramaImage.querySelector('.streetview__teleprompter');
  const totalTravelDistance = teleprompter.offsetHeight + window.innerHeight;
  const teleSizeRatio = teleprompter.offsetHeight / window.innerHeight;
  const baseSpeed = 30;
  const yawToPercent = (yaw) => {
    return ((100 / 180) * yaw) / 100;
  }

  pViewer = pannellum.viewer(panoramaImage, {
    "type": "equirectangular",
    "autoLoad": true,
    "panorama": panoramaImage.dataset.fullres,
    "preview": panoramaImage.dataset.panorama,
    "autoRotate": baseSpeed / teleSizeRatio,
    "autoRotateInactivityDelay": 2000,
    "showControls": false,
    "mouseZoom": false,
  });


  initial = window.setTimeout( () => {
    pViewer.startAutoRotate(baseSpeed / teleSizeRatio);
  }, 2000);

if (body.addEventListener) {
  body.addEventListener("mousewheel", MouseWheelHandler, false);
  body.addEventListener("DOMMouseScroll", MouseWheelHandler, false);
} else body.attachEvent("onmousewheel", MouseWheelHandler);

  pViewer.setYaw(180, false);


  let wayBack = false
  let isLoaded = false;
  const repeatOften = () => {
    const yawPercent = yawToPercent(pViewer.getYaw());
    yawPercent > 0 ? wayBack = true : wayBack = false;

    if (pViewer.isLoaded && !isLoaded) {
      isLoaded = true;
      setTimeout(() => {
        panoramaImage.classList.add('streetview--is-loaded');
      }, 500);
    }
    
    if (wayBack) {
      teleprompter.style.transform = `translateY(${(totalTravelDistance * (yawPercent/1.9))}px)`;
    } else {
      teleprompter.style.transform = `translateY(${(totalTravelDistance * (yawPercent/1.9))}px)`;
    }
    requestAnimationFrame(repeatOften);
  }
  requestAnimationFrame(repeatOften);

}

export const panoramaDestroy = () => {
  disableScroll.off()
  if (body.addEventListener) {
    body.removeEventListener("mousewheel", MouseWheelHandler, false);
    body.removeEventListener("DOMMouseScroll", MouseWheelHandler, false);
  } else body.attachEvent("onmousewheel", MouseWheelHandler);
  if (pViewer != null) {
    pViewer.destroy();
  }
}